import * as React from "react"

const Reviews = () => {
  return (
    <div>
      <h1>Reviews</h1>
    </div>
  )
}

export default Reviews